(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            xy: function () {
                var w = window,
                    d = document,
                    e = d.documentElement,
                    g = d.getElementsByTagName('body')[0],
                    x = w.innerWidth || e.clientWidth || g.clientWidth,
                    y = w.innerHeight || e.clientHeight || g.clientHeight;
                var scrollY = window.scrollY;
                return {x: x, y: y, scrollY: scrollY};
            },

            breakpoints: function () {
                var sm = 576,
                    md = 768,
                    lg = 992,
                    xl = 1200,
                    xxl = 1400;
                return {sm: sm, md: md, lg: lg, xl: xl, xxl: xxl};
            },

            switchElPos: function (breakpoint, wrapDesktop, wrapMobile, el) {
                var winWidth = Sage.common.xy().x;
                if (winWidth >= breakpoint) {
                    if (wrapDesktop.children().length === 0) {
                        el.detach().appendTo(wrapDesktop);
                    }
                } else {
                    if (wrapMobile.children().length === 0) {
                        el.detach().appendTo(wrapMobile);
                    }
                }
            },

            init: function () {

                /**
                 * Smooth scrolling when clicking an anchor link
                 */
                $('a[href*="#"]')
                    // Remove links that don't actually link to anything
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .click(function (event) {

                        if ($(this).attr('href') === '#enquiryForm') {
                            $(this).parent().addClass('btnFadeOut');
                        }

                        // On-page links
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            // Figure out element to scroll to
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            // Does a scroll target exist?
                            if (target.length) {
                                // Only prevent default if animation is actually gonna happen
                                event.preventDefault();
                                $('html, body').animate({
                                    scrollTop: target.offset().top
                                }, 1000, function () {
                                    // Callback after animation
                                    // Must change focus!
                                    var $target = $(target);
                                    $target.focus();
                                    if ($target.is(":focus")) { // Checking if the target was focused
                                        return false;
                                    } else {
                                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                        $target.focus(); // Set focus again
                                    }
                                });
                            }
                        }
                    });

            },

            finalize: function () {

            }
        },

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery);
